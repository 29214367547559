import React, { useContext, useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import Navbar from "../../components/Navbar/Navbar";
import {withStyles} from "@material-ui/styles";
import ProjectPageStyle from "./ProjectPageStyle";
import {Typography} from "@material-ui/core";
import arrow from "../../assets/PortfolioProject/arrow.png";
import ContactFooter from "../../components/HomePage/Contact/ContactFooter";
import { PortfolioContext } from 'context/PortfolioContext';


const ProjectPage = props => {
  const {classes} = props;

  let {project} = useParams();
  const {projects} = useContext(PortfolioContext);

  const [currentProject, setCurrentProject] = useState(null);

  useEffect(() => {
    console.log('project list', project, projects);

    console.log(projects.find(elem => elem.reactId === project))
    setCurrentProject(projects.find(elem => elem.reactId === project))
  }, [projects])

  // NOTE: this is a fix for a weird bug with Links
// https://stackoverflow.com/questions/45583358/open-link-at-top-of-page
  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  if(!currentProject) return null;

  return (
    <div className={classes.root}>
      <Navbar dark/>
      <div className={classes.angleBackgroundTop}/>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.titleContainer}>
            <Typography
              className={classes.title}
              variant="h2"
            >
              {currentProject.title}
            </Typography>
          </div>

          <div className={classes.subtitleContainer}>
            <div>
              <Typography
                variant="body1"
                className={classes.services}
              >
                SERVICES
              </Typography>
              <div>
                {currentProject.services.map((service, index) => (
                  <Typography
                    variant="body1"
                    key={service}
                  >
                    {service}
                  </Typography>
                ))}
              </div>
            </div>

            <div className={classes.descriptionContainer}>
              <div>
                <Typography
                  variant="body1"
                  className={classes.description}
                >
                  DESCRIPTION
                </Typography>
                <div>
                  <Typography
                    variant="body1"
                  >
                    {currentProject.overviewDescription}
                  </Typography>
                </div>
              </div>
            </div>

            <div className={classes.arrowContainer}>
              <img src={arrow} className={classes.arrow}/>
            </div>
          </div>
        </div>
        <div className={classes.headerImageContainer}>
          <img className={classes.headerImage} src={currentProject && currentProject.headerImage}/>
        </div>
        <div className={classes.paragraphContainer}>
          <Typography
            variant="h5"
            className={classes.paragraph}
          >
            {currentProject.paragraph}
          </Typography>
        </div>
        <div className={classes.imageContainer}>
          <div className={classes.leftColImageContainer}>
            <img src={currentProject && currentProject.leftColImage} className={classes.leftColImage}/>
          </div>
          <div className={classes.rightColImageContainer}>
            <img src={currentProject && currentProject.rightColImage} className={classes.rightColImage}/>
          </div>
        </div>
        <div className={classes.tabletImageContainer}>
          <img src={currentProject && currentProject.tabletImage} className={classes.tabletImage}/>
        </div>
        <div className={classes.footerImageContainer}>
          <img className={classes.footerImage} src={currentProject && currentProject.footerImage}/>
        </div>
      </div>

      <ContactFooter/>
    </div>
  )
}

ProjectPage.propTypes = {

};

export default withStyles(ProjectPageStyle)(ProjectPage)
