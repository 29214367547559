const ContactFooterStyle = theme => ({
  root: {
    backgroundColor: "#161616",
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: "center",
    width: "100%",
    height: "100vh",
    color: "#F3F3F3",
    position: "relative",
    paddingTop: "100px",
  },

  contactHeader: {
    width: "110%",
    height: "100px",
    display: "flex",
    position: "absolute",
    top: '-45px',
    left: 0,
    right: 0,
    backgroundColor: "#161616",
    transform: 'rotate(-2deg)',
  },

    logo: {
      width: "337px",
      height: "528px",
      position: "absolute",
      left: "50%",
      top: "50%",
      display: "block",
      transform: "translate(-50%, -50%)",
    },

    contactTitle: {
      fontWeight: "500 !important",
      color: '#5551F7',
      width: "100%",

      [theme.breakpoints.down('sm')]: {
        '&.MuiTypography-root': {
          fontSize: "45px",
        },
      },

      [theme.breakpoints.down('xs')]: {
        '&.MuiTypography-root': {
          fontSize: "40px",
        },
      },
    },

    socialTitle: {
      paddingBottom: "40px",
    },

    colContainerOuter: {
      paddingRight: "260px",
      paddingLeft: "214px",
      paddingTop: "120px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: 'flex-end',
      alignItems: "flex-start",


      [theme.breakpoints.down('lg')]: {
        paddingRight: "195px",
        paddingLeft: "160px",
      },
      [theme.breakpoints.down('md')]: {
        paddingRight: "130px",
        paddingLeft: "107px",
      },
      [theme.breakpoints.down('sm')]: {
        paddingRight: "65px",
        paddingLeft: "53px",
        alignItems: "center",
      },
      [theme.breakpoints.down('xs')]: {
        paddingRight: "0px",
        paddingLeft: "0px",
      },
    },

    colContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: 'flex-start',
      width: "100%",
    },

    leftCol: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: 'flex-start',
      color: "#F3F3F3",

      [theme.breakpoints.down('sm')]: {
        display: "none",
      },
    },

    rightCol: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: 'flex-start',
      flex: 1,
      width: "100%",
    },

    contactFooterSocial: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: 'center',
      marginBottom: "50px",
      color: "white",
      textDecoration: "none",
    },

    socialText: {
      paddingLeft: "25px",
    },

    bitBenders: {
      color: '#5551F7',
      paddingTop: "40px",
    },

    formContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: 'center',
      flex: 1,
      maxWidth: "820px",
      minWidth: "200px",

      [theme.breakpoints.down('lg')]: {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },

    formRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: 'center',
      marginBottom: "15px",
      width: "100%",

      [theme.breakpoints.down('sm')]: {
        flexDirection: "column",
      },
    },

    formInput: {
      backgroundColor: "transparent",
      color: '#F3F3F3',
      flex: 1,

      '&:first-of-type': {
        marginRight: "15px",

        [theme.breakpoints.down('sm')]: {
          marginRight: "0px",
          marginBottom: "15px",
        },
      },

      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        },
        '& .MuiInputBase-input': {
          color: "white",
        },
      },
    },


    formSubject: {
      backgroundColor: "transparent",
      color: '#F3F3F3',
      marginBottom: "15px",


      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        },
        '& .MuiInputBase-input': {
          color: "white",
        },
      },
    },

    formMessage: {
      backgroundColor: "transparent",
      color: '#F3F3F3',

      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        },
        '& .MuiInputBase-input': {
          color: "white",
        },
      },
    },

    formMessageContainer: {
      width: "100%",
    },

    input: {
      color: "#F3F3F3",
      fontSize: "18px",
      padding: "11px",
    },

    sendButton: {
      '&.MuiButton-root': {
        borderRadius:"40px",
        textTransform: "none",
        color:"white",
        borderColor: "white",
        marginTop: "60px",
        width:"222px",
        height: "69px",
        fontSize: "20px",
        transition: "0.3s",

        '&:hover': {
          backgroundColor: '#F3F3F3',
          color: "black",
        },

        [theme.breakpoints.down('xs')]: {
          width:"175px",
          height: "60px",
        },
      },
    },
})

export default ContactFooterStyle