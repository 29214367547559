const IntroductionStyle = theme => ({
  introduction: {
    backgroundColor: "#5551F7",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center',
    width: "100%",
    height: "430vh",
    color: theme.palette.text.primary,
    overflow: "auto",
  },

})

export default IntroductionStyle