const HomePortfolioStyle = theme => ({
  root: {
    // backgroundColor: "#181717",
    backgroundColor: "#161616",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: 'flex-start',
    width: "100%",
    minHeight: "115vh",
    color: "#F3F3F3",
    paddingTop: "160px",
    position: "relative",

    [theme.breakpoints.down('sm')]: {
      minHeight: "130vh",
    },

    [theme.breakpoints.down('sm')]: {
      minHeight: "150vh",
    },
  },

  logo: {
    width: "337px",
    height: "528px",
    position: "absolute",
    left: "50%",
    top: "50%",
    display: "block",
    transform: "translate(-50%, -50%)",
    pointerEvents: 'none'
  },

  container: {
    paddingLeft: "214px",
    paddingRight: "140px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: 'flex-start',

    [theme.breakpoints.down('md')]: {
      paddingLeft: "100px",
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      padding: 0,
      alignItems: 'center',

    },

    [theme.breakpoints.down('xs')]: {
      paddingBottom: '30px'
    }
  },

  leftCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: 'flex-start',
    flex: 1,

    [theme.breakpoints.down('sm')]: {
      marginBottom: "30px",
    },

    [theme.breakpoints.down('xs')]: {
      marginBottom: "30px",
    },
  },

  title: {
    width: "330px",

    [theme.breakpoints.down('xs')]: {
      width: "300px",
      paddingRight: "25px",
    },

  },

  cubeContainer: {

  },

  rightCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: 'flex-start',
    flex: 1,
    backgroundColor: "transparent",
  },

  innerRightCol: {
    width: "500px",
    overflow: "auto",
    height: "80vh",
    paddingRight: "50px",

    '&::-webkit-scrollbar': {
      height: '100px',
      width: '2px',
      position: 'relative',
      marginLeft: '9.33px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: '#262626',
      opacity: '0.48'
    },

    [theme.breakpoints.down('md')]: {
      width: "400px",
    },

    [theme.breakpoints.down('xs')]: {
      width: "275px",
      paddingRight: "25px",
      height: 'auto'
    },
  },

  projectImageContainer: {
    width: "100%",
    marginBottom: "70px",
  },

  image: {
    width: "100%",
    height: "100%",
    display: "block",
  },

  projectTitle: {
    marginBottom: "30px",
  },

  projectText: {
    marginBottom: "50px",
  },

})

export default HomePortfolioStyle
