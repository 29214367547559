const ProjectCardStyle = theme => ({

  projectContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    borderStyle: "solid",
    borderColor: "#181717",
    borderWidth: "1.5px 0px 1.5px 0px",
    paddingTop: "100px",
    paddingBottom: "100px",

    '&:first-of-type':{
      borderWidth: "0px 0px 1.5px 0px",
    },
    '&:last-of-type':{
      marginBottom: "60px",
    },

    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },

  },

  leftCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flex: 2,
    paddingRight: "124px",

    [theme.breakpoints.down('md')]: {
      alignItems: "center",
      justifyContent: "center",
      padding: 0,
    },

  },

  rightCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flex: 3,

    '& img' : {
      width: "100%",
      height: "100%",
    },
  },

  title: {

    '&.MuiTypography-root': {
      fontWeight: "500",
      paddingBottom: "25px",
      width: "100%",

      [theme.breakpoints.down('md')]: {
        fontWeight: "600",
        fontSize: "22px",
        textAlign: "center",
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: "16px",
      },

      [theme.breakpoints.down('xs')]: {
        textAlign: "left",
      },
    }
  },

  subtitle: {
    fontWeight: "400 !important",
    paddingBottom: "15px",

    [theme.breakpoints.down('md')]: {
      textAlign: "center",
    },

    [theme.breakpoints.down('xs')]: {
      textAlign: "left",
    },
  },

  body: {
    color: "#606060",

    [theme.breakpoints.down('md')]: {
      textAlign: "center",
      maxWidth: "740px",
    },

    [theme.breakpoints.down('xs')]: {
      textAlign: "left",
    },
  },

  link: {
    paddingTop: "150px",


    [theme.breakpoints.down('md')]: {
      paddingBottom: "20px",
    },
  },
})

export default ProjectCardStyle;