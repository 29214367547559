const NavbarStyle = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '84px',
    backgroundColor: 'transparent',
    padding: '15px 69px',
    position: 'fixed',
    top: '0',
    right: '0',
    zIndex: "900",

    [theme.breakpoints.down('sm')]: {
      padding: "15px 35px",
    },

    [theme.breakpoints.down('xs')]: {
      padding: "0 15px",
      height: '55px'
    },
  },

  darkRoot: {
    backgroundColor: 'white',
    boxShadow: '-1px 1px 7px 3px rgba(0,0,0,0.32);'
  },

  drawerButton: {
    display: "none",

    [theme.breakpoints.down('xs')]: {
      display: "block",

    },
  },

  logoContainer: {
    display: "block",

    [theme.breakpoints.down('xs')]: {
      display: "none",
    },
  },

  desktopLinks: {
    display: "flex",
    width: "100%",
    cursor: 'pointer',


    [theme.breakpoints.down('xs')]: {
      display: "none",
    },
  },

  mobileNavLink: {
    textDecoration: "none",
    color: "black",
  },

  mobileLink: {
    '& .MuiTypography-root': {

    },
  },

  logo: {
    width: '26px',
    height: '42px',
    cursor: 'pointer',
  },

  link: {
    color: 'white',
    textDecoration: 'none',
    marginLeft: '25px',
    fontWeight: '500',
    fontSize: "21px",
    fontFamily: "DM Sans",
  },

  darkLink: {
    color: 'black',
    textDecoration: 'none',
    marginLeft: '25px',
    fontWeight: '500',
    fontSize: "21px",
    fontFamily: "DM Sans",
  },

  selected: {
    color:'#5551F7',
  },

  contact: {
    marginLeft: "auto",
  },

  innerDrawer: {
    minWidth: '220px'
  }
});

export default NavbarStyle;
