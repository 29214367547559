import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Home from "./Pages/Home/Home";
import Portfolio from "./Pages/Portfolio/Portfolio";
import {ThemeProvider} from "@material-ui/styles";
import {MuiTheme} from "./assets/Theme";
import {createMuiTheme} from "@material-ui/core";
import ProjectPage from "./Pages/ProjectPages/ProjectPage";
import axios from 'axios';
import { SETTINGS } from 'settings';
import PortfolioContextProvider from 'context/PortfolioContext';


const Theme=createMuiTheme({
  ...MuiTheme
})

ReactDOM.render(
  <ThemeProvider theme={Theme}>
    <PortfolioContextProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path={"/"}>
            <Home/>
          </Route>
          <Route exact path={"/Portfolio"}>
            <Portfolio/>
          </Route>
          <Route exact path={"/Portfolio/:project"}>
            <ProjectPage/>
          </Route>
        </Switch>
      </BrowserRouter>
    </PortfolioContextProvider>
  </ThemeProvider>,

  document.getElementById('root')
);

