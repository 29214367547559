import React, { useImperativeHandle, useState } from 'react';
import { Drawer, IconButton, List, ListItem, ListItemText, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import NavbarStyle from './NavbarStyle';
import logo from '../../assets/NavBar/logo.png';
import { internalLinks } from './NavBarLinks';
import { Link, NavLink, useHistory } from 'react-router-dom';
import cn from 'classnames';
import darkLogo from '../../assets/NavBar/darkLogo.png';
import MenuIcon from '@material-ui/icons/Menu';

const Navbar = React.forwardRef((props, ref) => {
  const {classes, dark} = props;

  const history = useHistory()

  const [open, setOpen] = useState(false);

  // this toggles between transparent and solid
  const [solidMode, setSolidMode] = useState(false);

  const handleClick = (elemId) => {
    try {
      const anchor = document.querySelector('#' + elemId)
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    catch (error){
      history.push("/", {id: '#' + elemId})
    }
  };

  useImperativeHandle(ref, () => ({
    toggleSolidMode: () => {
      console.log('toggle solid mode');
      setSolidMode(!solidMode);
    }
  }));

  const links = internalLinks.map((data, index) => {
    if (data.isMobile) return;

    if (data.href) {
      return (
        <NavLink
          to={data.href ? data.href : '#'}

          className={cn(dark || solidMode ? classes.darkLink : classes.link, data.id === "contact" ? classes.contact : null)}
        >
          {data.text}
        </NavLink>
      )
    }
    else {
      return (
        <div
          className={cn(dark || solidMode ? classes.darkLink : classes.link, data.id === "contact" ? classes.contact : null)}
          onClick={
            () => {
              handleClick(data.id)
            }
          }
        >
          {data.text}
        </div>
      )
    }
  })

  const mobileLinks = internalLinks.map((data, index) => {
    if (data.href) {
      return (
        <ListItem button>
          <NavLink
            to={data.href ? data.href : '#'}
            className={classes.mobileNavLink}
          >
            <ListItemText
              primary={data.text}
              className={classes.mobileLink}
            >
            </ListItemText>
          </NavLink>
        </ListItem>
      )
    }
    else {
      return (
        <ListItem button
          onClick={
            () => {
              handleClick(data.id)
            }
          }
        >
          <ListItemText
            primary={data.text}
            className={classes.mobileLink}
          >
          </ListItemText>
        </ListItem>
      )
    }
  })

  const onClose = () => {
    console.log("close")
    setOpen(false)
  }

  const onOpen = () => {
    setOpen(true)
  }


  return (
    <nav className={cn(classes.root, dark || solidMode ? classes.darkRoot : null)} ref={ref}>
      <IconButton
        className={classes.drawerButton}
        onClick={onOpen}
      >
        <MenuIcon/>
      </IconButton>
      <Link
        to={"/"}
        className={classes.logoContainer}
      >
        {dark || solidMode ? (
          <img src={darkLogo} className={classes.logo}/>
        ) : (
          <img src={logo} className={classes.logo}/>
        )}
      </Link>

      <div className={classes.desktopLinks}>
        {links}
      </div>

      <Drawer
        open={open}
        onClose={onClose}
      >
        <List className={classes.innerDrawer}>
          {mobileLinks}
        </List>
      </Drawer>
    </nav>
  );
});

Navbar.propTypes = {
  classes: PropTypes.object
};

export default withStyles(NavbarStyle)(Navbar);
