import React from 'react';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';



const ContactFooterSocial = [
  {
    icon: <InstagramIcon/>,
    text: '@bitbenders',
    href:'#',
  },
  {
    icon: <TwitterIcon/>,
    text: '@bitbendersllc',
    href:'#',
  },
  {
    icon: <LinkedInIcon/>,
    text: '@bitbenders',
    href:'#',
  },
  {
    icon: <FacebookIcon/>,
    text: 'bitbenders',
    href:'#',
  },

];

export default ContactFooterSocial

