import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/styles";
import HomePortfolioStyle from "./HomePortfolioStyle";
import {Typography} from "@material-ui/core";
import bLogo from "./../../../assets/Background/bLogo.png";
import homePortfolioProjects from "./HomePorfolioProjects";

const HomePortfolio = props => {
  const {classes} = props;

  const portfolioProjects = homePortfolioProjects.map(project =>
    <div className={classes.project} key={project.title}>
      <div className={classes.projectImageContainer}>
        <img src={project.image} className={classes.image}/>
      </div>
      <div className={classes.projectTitle}>
        <Typography variant="h4" align="flex-start">
          {project.title}
        </Typography>
      </div>
      <div className={classes.projectText}>
        <Typography variant="subtitle2" align="justify">
          {project.text}
        </Typography>
      </div>
    </div>
  )


  return (
    <div className={classes.root} id="home-portfolio">
      <img src={bLogo} className={classes.logo}/>
      <div className={classes.container}>
        <div className={classes.leftCol}>
          <Typography className={classes.title} variant="h3" align="flex-start">
            Products we brought to life.
          </Typography>
          <div className={classes.cubeContainer}>
          </div>
        </div>
        <div className={classes.rightCol}>
          <div className={classes.innerRightCol}>
            {portfolioProjects}
          </div>
        </div>
      </div>
    </div>
  );
};

HomePortfolio.propTypes = {

};

export default withStyles(HomePortfolioStyle)(HomePortfolio)
