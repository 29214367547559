const HomeStyle = {
  root: {
    backgroundColor: "#5551F7",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: 'center',
    width: "100%",
    position: "relative",
    overflowX: "hidden",
  },

}

export default HomeStyle;