import React, {useEffect, useRef, useState} from "react";
import {IconButton, Typography, withStyles} from "@material-ui/core";
import ImageCanvasStyle from "./ImageCanvasStyle";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";


const ImageCanvas = props => {
  const {
    scrollHeight,
    numFrames,
    width,
    height,
    onLeavingAnimation,
    onEnteringAnimation,
    classes
  } = props;

  const canvasRef = useRef(null);
  const [images, setImages] = useState([]);
  const [frameIndex, setFrameIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const userLeavingAnimation = useRef(false);

  const getCurrentFrame = index => {
    return `/img/v4_${index.toString().padStart(5, '0')}.png`
  }

  const preloadImages = async () => {

    const imageList = []

    for (let i = 1; i <= numFrames; i++) {
      const img = new Image();
      const imgSrc = getCurrentFrame(i -1);
      img.src = imgSrc;
      imageList.push(img)
    }
    setImages(imageList)
  }

  const getCanvasScalingFactor = () => {
    return window.devicePixelRatio || 1;
  }

  const handleScroll = () => {
    const scrollFraction = window.scrollY / (scrollHeight - window.innerHeight);
    const index = Math.min(
      numFrames - 1,
      Math.ceil(scrollFraction * numFrames)
    );

    if (index <= 0 || index > numFrames) {
      return;
    }

    if (index > 70) {
      let frameOffset = index - 70
      setOpacity((1 - (0.05264 * frameOffset)))

      if(!userLeavingAnimation.current) {
        userLeavingAnimation.current = true;
        onLeavingAnimation((1 - (0.05264 * frameOffset)));
      }
    }
    else {
      if(userLeavingAnimation.current) {
        userLeavingAnimation.current = false;
        onEnteringAnimation();
      }
    }

    setFrameIndex(index);
  };

  const renderCanvas = () => {
    let pixelRatio = getCanvasScalingFactor();

    const context = canvasRef.current.getContext("2d");
    context.canvas.width = width * pixelRatio;
    context.canvas.height = window.innerHeight * pixelRatio;

    // if this is true, then it means the animation was playing and the user
    // resized the screen mid-animation. Allows for re-rendering images on resize.

    console.log('render canvas', {test: frameIndex < images.length, frameIndex, images: images.length})
    if(frameIndex < images.length) {
      console.log('re-render canvas image')
      context.clearRect(0, 0, context.canvas.width, window.innerHeight);
      context.drawImage(images[frameIndex], 0, 0, width * pixelRatio, window.innerHeight * pixelRatio);
    }
  };

  const scrollIntoView = (id) => {
    const anchor = document.querySelector(id)
    anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
  };

  useEffect(() => {
    console.log('width or height changed')
    renderCanvas();
  }, [width, height])

  useEffect(() => {
    preloadImages();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (!canvasRef.current || images.length < 1) {
      return;
    }

    const context = canvasRef.current.getContext("2d");
    let requestId;

    const render = () => {
      context.clearRect(0, 0, context.canvas.width, window.innerHeight);
      context.drawImage(images[frameIndex], 0, 0, width, window.innerHeight);
      requestId = requestAnimationFrame(render);
    };

    render();

    return () => cancelAnimationFrame(requestId);
  }, [frameIndex, images, width, height]);

  return (
    <div style={{height: scrollHeight}}>
      <div
        className={classes.canvas}
      >
        <canvas
          ref={canvasRef}
          width={width}
          height={height}
        />
        <div
          className={classes.content}
          style={{opacity:opacity}}
        >
          <Typography
            variant="h1"
            align="center"
            className={classes.header}
          >
            Hello, we're <br/>
            Bit Benders
          </Typography>
          <Typography variant="h6" align="center">
            A web development agency ready to design,
            develop and deploy your creativity.
          </Typography>
          <IconButton
            size="medium"
            variant="contained"
            className={classes.button}
            onClick = { () =>
              scrollIntoView("#home-portfolio")
            }
          >
            <ArrowDownwardIcon className={classes.arrow}/>
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default withStyles(ImageCanvasStyle)(ImageCanvas)
