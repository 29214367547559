import axios from 'axios';
import { SETTINGS } from 'settings';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';

axios.defaults.baseURL = SETTINGS.DJANGO_API_URL;

const responseBody = (response) => camelcaseKeys(response, {deep: true});
const responseError = (error) => {
  console.error(error);
  throw error;
};

export const requests = {
  get: (url) => axios.get(url).then(responseBody).catch(responseError),

  post: (url, body={}, headers={}, snakeCase=true) =>
    axios.post(url, snakeCase ? snakecaseKeys(body, { deep: true }) : body, {...headers})
      .then(responseBody).catch(responseError),

  put: (url, body) => axios.put(url, snakecaseKeys(body, { deep: true })).then(responseBody).catch(responseError),

  delete: (url) => axios.delete(url).then(responseBody).catch(responseError),

  patch: (url, body) => axios.patch(url, snakecaseKeys(body, { deep: true })).then(responseBody).catch(responseError)
};
