const ImageCanvasStyle = theme => ({
  canvas: {
    position: "fixed",
    top: "50%",
    left: "50%",
    maxWidth: "100%",
    maxHeight: "100vh",
    width: "100%",
    transform: "translate(-50%, -50%)",
    margin: "auto",
    display: "block",
  },

  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
    width: '70%',
    margin: '0 auto',
    minWidth: '320px'
  },

  button: {
    width: "73px",
    height: "73px",
    position: "fixed",
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",

    '&.MuiButtonBase-root': {
      backgroundColor: "#FFFFFF",
      marginTop: "45px",
      transition: "0.3s",

      '&:hover': {
        backgroundColor: '#C7C9D9',
      },
    },
  },

  header: {
    [theme.breakpoints.down('md')]: {
      fontSize: '75px'
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '65px'
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: '50px'
    }
  }
})

export default ImageCanvasStyle
