import React, { useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/styles';
import HomeStyle from './HomeStyle';
import Introduction from '../../components/HomePage/Introduction/Introduction';
import Navbar from '../../components/Navbar/Navbar';
import HomePortfolio from '../../components/HomePage/HomePortfolio/HomePortfolio';
import HomeTech from '../../components/HomePage/HomeTech/HomeTech';
import ContactFooter from '../../components/HomePage/Contact/ContactFooter';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

const Home = props =>{
  const {classes} = props;

  const location = useLocation();
  const navbarRef = useRef()

  const history = useHistory()

  useEffect(() => {
    if (location.state) {
      const anchor = document.querySelector(location.state.id)
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
      history.replace()
    }
  }, []);


  return(
    <div className={classes.root} id="homepage">
      <Navbar ref={navbarRef}/>
      <Introduction navbarRef={navbarRef}/>
      <HomePortfolio/>
      <HomeTech/>
      <ContactFooter/>
    </div>
  )
}

export default withStyles(HomeStyle)(Home)
