import React, { useContext, useEffect } from 'react';
import ProjectCard from "./ProjectCard";
import TennHome from "./../../assets/PortfolioProject/TennHome.png";
import Kraft from "./../../assets/PortfolioProject/Kraft.png";
import Fleet from "./../../assets/PortfolioProject/Fleet.png";
import TennHomeHeader from "./../../assets/PortfolioProject/TennHomeHeader.png";
import TennHomeLeftCol from "./../../assets/PortfolioProject/TennHomeLeftCol.png"
import TennHomeRightCol from "./../../assets/PortfolioProject/TennHomeRightCol.png"
import TennHomeFooter from "./../../assets/PortfolioProject/TennHomeFooter.png";
import { PortfolioContext } from 'context/PortfolioContext';

// export const projectList = [
//   {
//     title: '01 / TENNESSEE HOME FINDERS',
//     subtitle: 'Tennessee Home Finders is a real estate company that requested a website tailored just for them.',
//     body:
//       'We are able to pull data directly from the realtor ' +
//       'MLS and display it on their custom website. This ' +
//       'site gives users the ability to search for homes' +
//       'conveniently displayed as a list or as a map of their' +
//       ' area!',
//     link: 'More shots from this project',
//     image: TennHome,
//     id: 'THF',
//     services: ['Branding', 'Web Design', 'Web Development'],
//     description: 'Tennessee Home Finders is a real estate company that requested a website tailored just for them.',
//     headerImage: TennHomeHeader,
//     paragraph:
//       'Some random text about this project. This could be used as a “this is what we did and how we' +
//       ' did it” kinda thing. Preferably this would be around' +
//       'three of four lines. But I would not do less than 3 nor more then four.',
//     leftColImage: TennHomeLeftCol,
//     rightColImage: TennHomeRightCol,
//     tabletImage: TennHomeFooter,
//     footerImage: TennHomeFooter,
//   },
//   {
//     title: '02 / KRAFT CONSTRUCTION CO.',
//     subtitle: 'Kraft Construction Co. is a home remodeling company located in Cincinnati, Ohio.',
//     body:
//       'The owners came to us looking to update their ' +
//       'website in hopes of boosting their online presence. ' +
//       'Our creative team developed a modern website ' +
//       'with impressive designs using Wordpress. ',
//     link: 'More shots from this project',
//     image: Kraft,
//     id: 'kraft',
//     services: ['Branding', 'Web Design', 'Web Development'],
//     description:
//       'The owners came to us looking to update their website in hopes of boosting their online presence. ' +
//       'Our creative team developed a modern website with impressive designs using Wordpress. ',
//     paragraph:
//       'Some random text about this project. This could be used as a “this is what we did and how we' +
//       ' did it” kinda thing. Preferably this would be around' +
//       'three of four lines. But I would not do less than 3 nor more then four.',
//
//   },
//   {
//     title: '03 / TOTAL FLEET SOLUTIONS',
//     subtitle: 'Total Fleet Solutions was looking to build an app to inspect material handling equipment.',
//     body:
//       'They contacted us to make the app and needed it' +
//       'to sync to their existing CRM. Using Xcode and Swift' +
//       'we were able to develop a state of the art mobile ' +
//       'application meeting all of their needs.',
//     link: 'More shots from this project',
//     image: Fleet,
//     id: 'TFS',
//   },
//   {
//     title: '04 / YOUR PROJECT',
//     subtitle: 'What are you waiting for? Let’s give your project what it deserves.',
//     body: 'We’re super excited to hear your idea!',
//     link: 'More shots from this project',
//     image: '',
//   },
// ]

const ProjectCardList = props => {

  const {projects} = useContext(PortfolioContext);

  return (
    <div>
      {projects.map((data, index) => (
        <ProjectCard
          key={data.title}
          {...data}
        />
      ))}
    </div>
  );
};

ProjectCardList.propTypes = {};

export default ProjectCardList;
