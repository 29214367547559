import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/styles";
import ContactFooterStyle from "./ContactFooterStyle";
import {Button, TextField, Typography} from "@material-ui/core";
import bLogo from "./../../../assets/Background/bLogo.png";
import ContactFooterSocial from "./ContactFooterLinks";
import { requests } from 'services';

const ContactFooter = props => {
  const {classes} = props;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();
    // if any of the inputs are either empty or just have spaces in them but no text
    // just stop submitting
    if(!name.trim() || email.trim() || subject.trim() || message.trim()) return;

    setLoading(true);

    requests.post('/contact-us/', {name, email, subject, message})
      .then(response => {
        console.log('success!', response);

        setName('');
        setEmail('');
        setSubject('');
        setMessage('');

      })
      .catch(error => {
        console.log('error', error);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  return (
    <div className={classes.root} id="contact">

      <img src={bLogo} className={classes.logo}/>
      <Typography
        variant="h2"
        align="center"
        className={classes.contactTitle}
      >
        Let’s start a journey
      </Typography>
      <div className={classes.colContainerOuter}>
        <div className={classes.colContainer}>
          <div className={classes.leftCol}>
            <Typography
              variant="body1"
              align="left"
              className={classes.socialTitle}
            >
              SOCIALS
            </Typography>
            {ContactFooterSocial.map((data, index) => (
              <a className={classes.contactFooterSocial} href={data.href} key={index}>
                {data.icon}
                <Typography
                  className={classes.socialText}
                >
                  {data.text}
                </Typography>
              </a>
            ))}
          </div>
          <div className={classes.rightCol}>
            <form className={classes.formContainer} onSubmit={submitForm}>
              <div className={classes.formRow}>
                <TextField
                  className={classes.formInput}
                  placeholder="Name"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes:{input: classes.input},
                  }}
                  onChange={e => setName(e.target.value)}
                />
                <TextField
                  className={classes.formInput}
                  placeholder="Email"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes:{input: classes.input},
                  }}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
              <div className={classes.formRow}>
                <TextField
                  className={classes.formSubject}
                  placeholder="Subject"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes:{input: classes.input},
                  }}
                  onChange={e => setSubject(e.target.value)}
                />
              </div>
              <div className={classes.formMessageContainer}>
                <TextField
                  className={classes.formMessage}
                  placeholder="Type your message here..."
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={10}
                  InputProps={{
                    classes:{input: classes.input},
                  }}
                  onChange={e => setMessage(e.target.value)}
                />
              </div>
              <Button
                variant="outlined"
                size="large"
                className={classes.sendButton}
                disabled={loading}
                // required for the form to submit on click
                type={'submit'}
              >
                Send
              </Button>
            </form>
          </div>
        </div>

        <div className={classes.bitBenders}>
          <Typography
            variant="body1"
            align="left"
          >
            Bit Benders - 2021
          </Typography>
        </div>
      </div>
    </div>
  );
};

ContactFooter.propTypes = {

};

export default withStyles(ContactFooterStyle)(ContactFooter)
