import React from "react";
import {withStyles} from "@material-ui/styles";
import ProjectCardStyle from "./ProjectCardStyle";
import {Typography} from "@material-ui/core";
import {Link} from "react-router-dom";


const ProjectCard = props => {
  const {
    classes,
    title,
    overviewSubtitle,
    overviewBody,
    overviewLinkText,
    overviewImage,
    reactId,
  } = props;

  return (
    <div className={classes.projectContainer}>
      <div className={classes.leftCol}>
        <Typography className={classes.title}>
          {title}
        </Typography>

        <Typography className={classes.subtitle}>
          {overviewSubtitle}
        </Typography>

        <Typography className={classes.body}>
          {overviewBody}
        </Typography>

        <Link to={`/Portfolio/${reactId}`}>
          <Typography className={classes.link}>
            {overviewLinkText}
          </Typography>
        </Link>
      </div>
      <div className={classes.rightCol}>
        <img src={overviewImage}/>
      </div>
    </div>
  )
}

export default withStyles(ProjectCardStyle)(ProjectCard);
