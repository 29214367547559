import React from "react";
import HomePortfolioKraft from "./../../../assets/HomePortfolioProjects/HomePortfolioKraft.png";
import TennHomeFinder from "./../../../assets/HomePortfolioProjects/TennHomeFinder.png";
import FleetSolutions from "./../../../assets/HomePortfolioProjects/FleetSolutions.png";

const homePortfolioProjects = [
  {
    image: HomePortfolioKraft,
    title:
      '02 / Kraft Construction Co. \n' +
      'Home remodeling company',
    text:
      'Kraft Construction Co. is a home remodeling company located in \n' +
      'Cincinnati, Ohio. The owners came to us looking to update their \n' +
      'website in hopes of boosting their online presence. Our creative\n' +
      'team developed a modern website with impressive designs using \n' +
      'Wordpress. Even now that the website is functional, we continue \n' +
      'to help by hosting the website on AWS and closely monitoring the \n' +
      'server to ensure that it is always running efficiently.',
  },
  {
    image: TennHomeFinder,
    title: '01 / TENNESSEE HOME FINDERS',
    text:
      'We are able to pull data directly from the realtor MLS and display ' +
      'it on their custom website. This site gives users the ability to ' +
      'search for homes conveniently displayed as a list or as a map of ' +
      'their area!',
  },
  {
    image: FleetSolutions,
    title: '03 / TOTAL FLEET SOLUTIONS',
    text:
      'Total Fleet Solutions was looking to build an app to inspect' +
      'material handling equipment. They contacted us to make the app ' +
      'and needed it to sync to their existing CRM. Using Xcode and Swift ' +
      'we were able to develop a state of the art mobile application meeting' +
      'all of their needs. The data is stored on their cloud CRM where they ' +
      'are able to analyze the data to support their customers.',
  },
];

export default homePortfolioProjects;