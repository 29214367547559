import React, {useEffect, useState} from 'react';
import {withStyles} from "@material-ui/styles";
import IntroductionStyle from "./IntroductionStyle";
import ImageCanvas from "../ImageCanvas/ImageCanvas";

const Introduction = props => {
  const {classes, navbarRef} = props;

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    console.log("handle resize", window.innerWidth, window.innerHeight)
    setWindowHeight(window.innerHeight)
    setWindowWidth(window.innerWidth)
  };

  const handleLeavingAnimation = () => {
    // set the nav to white here
    console.log('on leave animation', navbarRef)
    navbarRef.current?.toggleSolidMode()
  }

  const handleEnteringAnimation = () => {
    console.log('on enter animation', navbarRef)
    navbarRef.current?.toggleSolidMode()
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    console.log('navbar ref', navbarRef)
  }, [navbarRef])

  return (
    <div className={classes.introduction}>
      <ImageCanvas
        scrollHeight={4000}
        width={windowWidth}
        height={windowHeight}
        numFrames={90}

        // these are used to set the navbar background when leaving/entering
        // the animation at certain frames
        onLeavingAnimation={handleLeavingAnimation}
        onEnteringAnimation={handleEnteringAnimation}
      />
    </div>
  );
};

Introduction.propTypes = {

};

export default withStyles(IntroductionStyle)(Introduction)
