const ProjectPageStyle = theme => ({
  root:{
    backgroundColor: "#E5E5E5",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: 'center',
    width: "100%",
    position: "relative",
    overflowX: "hidden",
  },

  angleBackgroundTop: {
    width: "120%",
    height: "1200px",
    display: "flex",
    position: "absolute",
    top: '1200px',
    left: "-30px",
    right: 0,
    backgroundColor: "#161616",
    transform: 'rotate(-2deg)',

    [theme.breakpoints.down('1600')]: {
      height: "950px",
    },
    [theme.breakpoints.down('xs')]: {
      height: "900px",
    },
  },

  container: {
    paddingLeft: "150px",
    paddingRight: "150px",
    marginTop: "254px",
    marginBottom: "50px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    position: "relative",

    [theme.breakpoints.down('lg')]: {
      paddingLeft: "100px",
      paddingRight: "100px",
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: "50px",
      paddingRight: "50px",
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      marginTop: "200px",
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: "150px",
    },
  },

  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: "150px",
  },

  titleContainer: {
    width: "100%",
    display: "flex",
    paddingBottom: "50px",
    marginBottom: "50px",
  },

  title: {
    fontWeight: "500 !important",
    width: "100%",
    '&.MuiTypography-root': {

      [theme.breakpoints.down('md')]: {
        fontSize: "50px",
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: "40px",
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: "35px",
      },
    },
  },

  subtitleContainer: {
    paddingLeft: "150px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "90%",


    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      paddingLeft: "0",
    },
  },

  services: {
    fontWeight: "500 !important",
    paddingBottom: "25px",

    [theme.breakpoints.down('sm')]: {
      fontWeight: "600 !important",
    },
  },

  description: {
    fontWeight: "500 !important",
    paddingBottom: "25px",

    [theme.breakpoints.down('sm')]: {
      fontWeight: "600 !important",
    },
  },

  descriptionContainer: {
    maxWidth: "356px",

    [theme.breakpoints.down('lg')]: {
      marginLeft: "20px",
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: "0px",
      marginTop: "30px",
    },


  },

  arrow: {
    width: "43px",
    height: "38px",

    [theme.breakpoints.down('lg')]: {
      marginLeft: "20px",
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: "0px",
      marginTop: "30px",
    },
  },

  headerImageContainer: {
    width: "100%",
  },

  headerImage: {
    width: "100%",
    boxShadow: "2px 2px 15px 0px rgba(0,0,0,0.3)",
  },

  paragraphContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    width: "100%",
    marginTop: "80px",
    marginBottom: "80px",

    [theme.breakpoints.down('xs')]: {
      alignItems: "center",
      marginTop: "205px",
    },
  },

  paragraph: {
    width: "1000px",
    color: "#F3F3F3",

    [theme.breakpoints.down('md')]: {
      width: "700px",
    },
    [theme.breakpoints.down('750')]: {
      width: "600px",
    },
    [theme.breakpoints.down('sm')]: {
      width: "500px",
    },
    [theme.breakpoints.down('xs')]: {
      width: "225px",
    },
  },

  imageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",

    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
    },
  },

  leftColImage: {
    width: "100%",
  },

  rightColImageContainer: {
    [theme.breakpoints.down('lg')]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down('md')]: {
      marginTop: "20px",
      marginLeft: "0px",
    },
  },

  rightColImage: {
    width: "100%",
  },

  tabletImageContainer: {
    borderRadius: "90px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    marginTop: "300px",
    boxShadow: "2px 2px 15px 0px rgba(0,0,0,0.3)",
    width: "100%",

    [theme.breakpoints.down('sm')]: {
      borderRadius: "35px",
      marginTop: "250px",
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: "25px",
      marginTop: "100px",
    },
  },

  tabletImage: {
    borderRadius: "90px",
    backgroundColor: "#181717",
    border: "solid #181717 20px",
    width: "100%",

    [theme.breakpoints.down('md')]: {
      borderRadius: "35px",
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: "35px",
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: "25px",
      borderWidth: "14px",
    },
  },

  footerImageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "250px",
    marginBottom: "100px",

    [theme.breakpoints.down('xs')]: {
      marginTop: "100px",
    },
  },

  footerImage: {
    width: "100%",
    boxShadow: "2px 2px 15px 0px rgba(0,0,0,0.3)",
  },
})

export default ProjectPageStyle