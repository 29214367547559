const HomeTechStyle = theme => ({
  root: {
    backgroundColor: "#F3F3F3",
    display: "flex",
    flexDirection: "row",
    justifyContent: 'flex-start',
    alignItems: "flex-start",
    width: "100%",
    minHeight: "100vh",
    color: "#181717",
    position: "relative",
    paddingTop: "100px",
  },

  homeTechHeader: {
    width: "110%",
    height: "100px",
    display: "flex",
    position: "absolute",
    top: '-45px',
    left: 0,
    right: 0,
    backgroundColor: "#F3F3F3",
    transform: 'rotate(-2deg)',
  },

  logo: {
    width: "337px",
    height: "528px",
    position: "absolute",
    left: "65%",
    top: "35%",
    display: "block",
    transform: "translate(-50%, -50%)",

    [theme.breakpoints.down(1360)]: {
      opacity: "0.2",
    },

    [theme.breakpoints.down('xs')]: {
      display: "none",
    },
  },

  colContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'flex-start',
    width: "100%",
    paddingLeft: "214px",
    paddingRight: "330px",
    backgroundColor: "transparent",
    position: "relative",

    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      padding: 0,
      alignItems: 'flex-start',
      margin: 15,
    },
  },

  paragraphTitle: {
    paddingBottom: "50px",
    fontWeight: "500",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "500px",

    [theme.breakpoints.down('md')]: {
      width: "400px",
    },

    [theme.breakpoints.down('xs')]: {
      width: "300px",
    },
  },

  paragraph: {
    color: "#606060",
    paddingBottom: "171px",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "400px",

    [theme.breakpoints.down('xs')]: {
      width: "260px",
      padding: 0,
      margin: 0,
    },
  },

  skillsSoftwareContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
    alignItems: "flex-start",
    marginTop: "50px",
    width: "90%",
    minWidth: "610px",

    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
      marginBottom: "100px",
    },


    [theme.breakpoints.down('sm')]: {
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginBottom: "100px",
    },
  },

  skillsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    
    [theme.breakpoints.down('md')]: {
      marginBottom: "20px",
    },

    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    },
  },


  skillSoftwareList: {
    '& p': {
      minWidth: "200px",
      maxWidth: "450px",
    },

    [theme.breakpoints.down('md')]: {
      '& p': {
        maxWidth: "436px",
      },
    },

    [theme.breakpoints.down('sm')]: {
      width: "400px",
    },

    [theme.breakpoints.down('xs')]: {
      width: "280px",
    },
  },

  skillSoftwareContainerTitle:{
    width: "155px",
    [theme.breakpoints.down('xs')]: {
      fontWeight: "600 !important",
    },
  },

  softwareContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'flex-start',
    alignItems: "flex-start",

    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    },
  },

})

export default HomeTechStyle