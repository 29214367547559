import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { requests } from 'services';

export const PortfolioContext = createContext();

const PortfolioContextProvider = props => {
  const {
    children // a default prop in react representing HTML inside of this component's tag
  } = props;
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    requests.get('/projects')
      .then(response => {
        console.log('success!', response.data);
        setProjects(response.data);
      })
      .catch(error => {
        console.log('error', error);
      })
  }, [])

  return (
    <PortfolioContext.Provider value={{projects}}>
      {children}
    </PortfolioContext.Provider>
  );
};

PortfolioContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default PortfolioContextProvider;
