import React from "react";

const internalLinks = [
  {
    text: 'Home',
    id: 'homepage',
    isMobile: true,
  },
  {
    text: 'Tech',
    id: 'tech',
  },
  {
    text: 'Portfolio',
    href: '/portfolio',
    id: 'portfolio',
  },
  {
    text: 'Contact',
    id: 'contact',
  },

];

export {internalLinks};