//special variable provided by MaterialUI to override their default colors
//we use this in index.js
const MuiTheme = {

  palette: {
    common: {
      black: '#000',
      white: '#fff',
      gray: '#878787'
    },
    background: {
      paper: '#FFF',
      default: '#F3F3F3'
    },
    primary: {
      main: '#fff',
      hover: '#323232',
      contrastText: '#FFF'
    },
    secondary: {
      main: '#FFE001',
      dark: '#E7CA01',
      hover: '#E7CA01',
      contrastText: '#000'
    },
    error: {
      light: '#e57373',
      main: 'rgba(245, 43, 7, 1)',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
    text: {
      primary: '#ffffff',
      secondary: '#000000',
    },
  },
};

const navRouteMarginTop = '75px';
export {
  MuiTheme,
  navRouteMarginTop
};