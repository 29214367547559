import React, {useEffect} from "react";
import {withStyles} from "@material-ui/styles";
import PortfolioStyle from "./PortfolioStyle";
import Navbar from "../../components/Navbar/Navbar";
import ContactFooter from "../../components/HomePage/Contact/ContactFooter";
import {Typography} from "@material-ui/core";
import ProjectCardList from "../../components/PortfolioPage/ProjectCardList";
import arrow from "../../assets/PortfolioProject/arrow.png"

const Portfolio = props =>{
  const {classes} = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return(
    <div className={classes.root}>
      <Navbar dark/>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.titleContainer}>
            <Typography
              variant="h3"
              className={classes.title}
            >
              Products we brought to life
            </Typography>
          </div>
          <div className={classes.paragraphContainer}>
            <Typography
              variant="h6"
              className={classes.paragraph}
            >
              A web development agency ready to design, develop and deploy your creativity.
            </Typography>
          </div>
        </div>
        <div>
          <img src={arrow}/>
        </div>
        <div className={classes.portfolioContainer}>
          <ProjectCardList/>
        </div>
      </div>
      <ContactFooter/>
    </div>
  )
}

export default withStyles(PortfolioStyle)(Portfolio)