import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/styles";
import HomeTechStyle from "./HomeTechStyle";
import {Typography} from "@material-ui/core";
import bLogo2 from "../../../assets/Background/bLogo2.png";

const HomeTech = props => {
  const {classes} = props;


  return (
    <div className={classes.root} id='tech'>
      <div className={classes.homeTechHeader}>
      </div>
      <img src={bLogo2} className={classes.logo}/>
      <div className={classes.colContainer}>
        <div className={classes.paragraphTitle}>
          <Typography
            variant="h3"
            align="flex-start"
          >
            Bringing products to life is what we do best
          </Typography>
        </div>
        <div className={classes.paragraph}>
          <Typography
            variant="h6"
            align="flex-start"
          >
            We offer a one stop shop for all your development needs. Because we are experts in all things technology,
            we can design, develop, and deploy unique applications that work for you and your company.
          </Typography>
        </div>
        <div className={classes.skillsSoftwareContainer}>
          <div className={classes.skillsContainer}>
            <Typography
              variant="body1"
              align="flex-start"
              className={classes.skillSoftwareContainerTitle}
            >
              Main Skills
            </Typography>
            <div className={classes.skillSoftwareList}>
              <Typography
                variant="body1"
                align="flex-start"
              >
                Mobile App Development
              </Typography>
              <Typography
                variant="body1"
                align="flex-start"
              >
                Web Development
              </Typography>
              <Typography
                variant="body1"
                align="flex-start"
              >
                Web Hosting
              </Typography>
            </div>
          </div>
          <div className={classes.softwareContainer}>
              <Typography
                variant="body1"
                align="flex-start"
                className={classes.skillSoftwareContainerTitle}
              >
                Main Software
              </Typography>
            <div className={classes.skillSoftwareList}>
              <Typography
                variant="body1"
                align="flex-start"
              >
                Xcode, Swift, Android Studio, Java, React Native, Firebase, Node.js,
              </Typography>
              <Typography
                variant="body1"
                align="flex-start"
              >
                Django, React, Wordpress, PHP, ASP.NET, JavaScript, PostgreSQL, MySQL, AWS, Ubuntu, Nginx,
              </Typography>
              <Typography
                variant="body1"
                align="flex-start"
              >
                Apache, Windows Server, Microsoft IIS, Let’s Encrypt
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

HomeTech.propTypes = {};

export default withStyles(HomeTechStyle)(HomeTech)