const PortfolioStyle = theme => ({
  root:{
    backgroundColor: "#E5E5E5",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: 'center',
    width: "100%",
    position: "relative",
    overflowX: "hidden",
  },

  container: {
    margin: "0 auto",
    marginTop: "254px",
    marginBottom: "50px",
    paddingLeft: "300px",
    paddingRight: "300px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",

    [theme.breakpoints.down('lg')]: {
      paddingLeft: "200px",
      paddingRight: "200px",
    },

    [theme.breakpoints.down('md')]: {
      paddingLeft: "100px",
      paddingRight: "100px",
      justifyContent: "center",
      alignItems: "center",
    },

    [theme.breakpoints.down('xs')]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      marginTop: "100px",
    },
  },

  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    width: "100%",
    marginBottom: "250px",

    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "auto",
      marginBottom: "100px",
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: "100px",
    },
  },

  titleContainer: {
    flex:1,
  },

  title: {
    fontWeight: "500 !important",
    width: "330px",

    [theme.breakpoints.down('sm')]: {
      '&.MuiTypography-root': {
        fontSize: "45px",
        textAlign: "center",
      },
    },

    [theme.breakpoints.down('xs')]: {
      '&.MuiTypography-root': {
        fontSize: "40px",
        marginBottom: "40px",
        width: "250px",
      },
    },
  },

  paragraphContainer: {
    flex:1,
    color: "#606060",

    [theme.breakpoints.down('lg')]: {
      marginLeft: "20px",
    },

    [theme.breakpoints.down('md')]: {
      marginTop: "20px",
      marginLeft: 0,
    },
  },

  paragraph: {
    maxWidth: "420px",

    [theme.breakpoints.down('sm')]: {
      '&.MuiTypography-root': {
        fontSize: "20px",
        textAlign: "center",
        maxWidth: "550px",
      },
    },

    [theme.breakpoints.down('xs')]: {
      '&.MuiTypography-root': {
        marginBottom: "40px",
      },
    },
  },

  portfolioContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: 'center',
    width: "100%",
  },
})

export default PortfolioStyle;